import { cookieGet, cookieSet } from "./_helpers";

export default class CookiesPopup {
  constructor() {
    this.cookieContainer = document.querySelector(".cookie");
    this.cookieCloseBtn = document.querySelector(".cookie-close");

    this.mount();
  }

  addCookieCloseEvent = () => {
    this.cookieCloseBtn.addEventListener("click", (e) => {
      this.handleCookieHide();
      cookieSet("allow_cookie", 1, "/", 9999);
    });
  };

  handleCookieShow = () => {
    this.addCookieCloseEvent();

    setTimeout(() => {
      this.cookieContainer.classList.add("cookie-showed");
    }, 1000);
  };

  handleCookieHide = () => {
    this.cookieContainer.classList.remove("cookie-showed");
  };

  mount = () => {
    cookieGet("allow_cookie") ? null : this.handleCookieShow();
  };
}
